import React from "react";
import * as styles from "./OurCustomersMain.module.scss";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import AES from "../../../images/HomeMain-Images/AES.svg";
import Barmer from "../../../images/HomeMain-Images/Barmer.svg";
import HPV from "../../../images/HomeMain-Images/HPV.svg";
import Otto from "../../../images/HomeMain-Images/OTTO.svg";
import Korber from "../../../images/HomeMain-Images/Korber.svg";
import Adference from "../../../images/HomeMain-Images/Adference.svg";
import Bigpoint from "../../../images/HomeMain-Images/Bigpoint.svg";
import Glasswall from "../../../images/HomeMain-Images/Glasswall.svg";
import HapagLloyd from "../../../images/HomeMain-Images/Hapag Lloyd.svg";
import Vodafone from "../../../images/HomeMain-Images/Vodafone.svg";
import Gothaer from "../../../images/HomeMain-Images/Gothaer.svg";
import Audi from "../../../images/HomeMain-Images/Audi.svg";
import SE from "../../../images/HomeMain-Images/siemens_energy.svg";
import Ericsson from "../../../images/HomeMain-Images/ericsson.svg";
import now from "../../../images/HomeMain-Images/nowd.svg";
import Addidas from "../../../images/HomeMain-Images/addidas.svg";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import { CarouselProvider, Slider } from "pure-react-carousel";
import CaseStudiesSliderButtons from "../../HomeUiComponents/CaseStudiesMain/Slider/CaseStudiesSliderButtons";
import OurCustomersSlider from "../../OptimizationUiComponents/Slider/OurCustomersSlider";
import TitleForSections from "../../Shared/TitleForSection/TitleForSections";
import cx from "classnames";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import translate from "../../../i18n/translate";

const OurCustomersMain = () => {
  const { width } = UseWindowSize();
  const customerSlider = [
    [now, Barmer],
    [Audi, HapagLloyd],
    [Otto, Gothaer],
    [Addidas, Ericsson],
    [SE, Vodafone],
    [Bigpoint, HPV],
    [Glasswall, Korber],
    [Adference, AES],
  ];
  return (
    <div className={styles.customers}>
      <InnerWrapper>
        <div>
          <div className={styles.customerSubDiv}>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <div className={styles.carouselMainContainer}>
              <CarouselProvider
                className={cx(
                  styles.carouselContainer,
                  " customer_carousel__container carousel__container"
                )}
                naturalSlideWidth={120}
                naturalSlideHeight={125}
                totalSlides={customerSlider?.length}
                visibleSlides={width < 1200 ? 1 : 2}
                currentSlide={0}
                infinite
              >
                <div className={styles.customerHeading}>
                  <TitleForSections
                    headerClassName={styles.caseStudiesHeader}
                    title={translate("home-ourCustomers")}
                  />
                  <CaseStudiesSliderButtons
                    sliderButtonsClassName={cx(
                      styles.sliderBtns,
                      styles.btnMargin
                    )}
                  />
                </div>
                <Slider className={styles.sliderComp}>
                  {customerSlider.map((item, index) => {
                    return (
                      <OurCustomersSlider
                        key={index}
                        sliderIndex={index}
                        sliderImage={item}
                      />
                    );
                  })}
                </Slider>
              </CarouselProvider>
            </div>
          </div>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default OurCustomersMain;
